export default {
    swiftlane: 'Swiftlane',
    errorHappened: 'There was an error.',
    name: 'Name',
    displayName: 'Display Name',
    nameOnReader: 'Name - Displayed on Reader',
    site: 'Site',
    done: 'Done',
    selectSite: 'Select a Site',
    selectSites: 'Select Site/s',
    none: 'None',
    all: 'All',
    filters: 'Filters',
    wantDelete: 'Are you sure you want to delete?',
    buttons: {
        skip: 'Skip',
        cancel: 'Cancel',
        remove: 'Remove',
        save: 'Save',
        yes: 'Yes',
        no: 'No',
        delete: 'Delete',
        submit: 'Submit',
        saveChanges: 'Save Changes',
        saveSettings: 'Save Settings',
        edit: 'Edit',
        add: 'Add',
        selectAll: 'Select All',
        unSelectAll: 'Un-Select All',
        archive: 'Archive',
        deactivate: 'Deactivate',
        activate: 'Activate',
        setDefaults: 'Set to default',
        upload: 'Upload',
        apply: 'Apply',
        refreshData: 'Refresh data',
        reset: 'Reset',
        create: 'Create',
        uninstall: 'Uninstall',
        configure: 'Configure',
        install: 'Install',
        copy: 'Copy',
        resolve: 'Resolve',
        resolveSelected: 'Resolve selected',
        continue: 'Continue',
        close: 'Close',
        next: 'Next',
        back: 'Back',
        assign: 'Assign',
        send: 'Send',
        download: 'Download',
        ok: 'OK',
        update: 'Update',
        done: 'Done',
        connect: 'Connect',
        preview: 'Preview',
        revoke: 'Revoke',
        addConnection: 'Add Connection',
        details: 'Details',
        duplicate: 'Duplicate',
        reValidate: 'Re-validate',
        refresh: 'Refresh',
        print: 'Print',
    },
    user: 'User',
    users: 'Users',
    lastHour: 'Last Hour',
    last6Hours: 'Last 6 hours',
    last24Hours: 'Last 24 hours',
    last7Days: 'Last 7 days',
    customDate: 'Custom Date',
    general: 'General',
    enterEmailAddress: 'Enter email address',
    here: 'here',
    idDownloadNotStart: 'if download does not start',
    createdBy: 'Created By',
    createdTime: 'Created Time',
    enterPositiveDigits: 'Enter positive digits only',
    status: 'Status',
    assignedUser: 'Assigned user',
    selectAll: 'Select All',
    action: 'Action',
    sortBy: 'Sort by',
    default: 'Default',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    fieldIsRequired: 'Field is required',
    isNotValidEmail: 'Entered value is not a valid email!',
    actions: 'Actions',
    offline: 'Offline',
    online: 'Online',
    search: 'Search',
    enterSearchQuery: 'Enter search query',
    clickOrDrag: 'Click or drag file to this area to upload',
    searchSelectUsers: 'Search & select users',
    searchSelect: 'Search & Select',
    id: 'ID',
    humanReadableName: 'Human Readable Name',
    email: 'Email',
    text: 'Text',
    message: 'Message',
    role: 'Role',
    lastName: 'Last Name',
    firstName: 'First Name',
    enterValue: 'Enter value',
    countryCode: 'Country Code',
    phoneNumber: 'Phone Number',
    enterPhoneNumber: 'Enter Phone Number',
    view: 'View',
    accessPoints: 'Access Points',
    accessPoint: 'Access Point',
    sites: 'Sites',
    workspace: 'Account name',
    showBy: 'Show by',
    userId: 'User ID',
    viewAll: 'View All',
    viewLess: 'View Less',
    allStatuses: 'All statuses',
    relay: 'Relay',
    userRoles: {
        user: 'User',
        admin: 'Admin',
    },
    navSettings: {
        workspaces: 'Accounts',
        addWorkspace: 'Add an Account',
        profileSettings: 'Profile Settings',
        logout: 'Logout',
        loggedAs: 'Logged in as',
        searchWorkspace: 'Search Account',
    },
    navigation: {
        accessControl: {
            root: 'Access Control',
            activityFeed: 'Activity Feed',
            users: 'Users',
            accessGroups: 'Access Groups',
            sitesAccessPoints: 'Sites & Access Points',
            visitorAccess: 'Intercom Settings',
            visitorPins: 'Visitor Pins',
            schedules: 'Schedules',
            healthCheck: 'Health Check',
            integrations: 'Integrations',
            settings: 'Settings',
            reports: 'Reports',
            keyCards: 'Key Cards',
            hardware: 'Hardware',
            tailgatingDetection: 'Tailgating Detection',
            myProfile: 'My Profile',
            sites: 'Sites',
            subscriptions: 'Subscriptions',
            announcements: 'Building Announcements',
            auditLogs: 'Audit Logs',
            packageDelivery: 'Package Delivery',
            swiftCall: 'Swiftcall Connect',
        },
        commandCenter: {
            root: 'Command Center',
            rootShort: 'CC',
            swiftReaders: 'Swift Readers',
            workspaces: 'Accounts',
            doorControllers: 'Door Controllers',
            inventoryManagement: 'Inventory Management',
            acuHub: 'ACU Hub',
            vms: 'VMS',
            sites: 'Sites',
            userFeedback: 'User Feedbacks',
            iosDevices: 'iOS Devices',
            users: 'Users',
            accessLogs: 'Read/Write access logs',
            referralAndOffers: 'Referrals and Offers',
            billing: 'Billing',
            invoices: 'Invoices',
            attUsageTracking: 'AT&T Tracking',
            deviceManager: 'Device Manager',
            partnerManager: 'Integration Partners',
        },
        rentGpt: {
            root: 'RentGPT',
            chatHistory: 'Chat History',
            contentModeration: 'Content Moderation',
            leadActions: 'Leads',
            formSubmissions: 'Form Submissions',
            settings: 'Settings',
            gallery: 'Gallery',
        },
        billing: {
            root: 'Billing',
            invoices: 'Invoices',
        },
        help: 'Help & Support',
    },
    accessPointToDCUMapping: 'Access Point To Door Controllers Mapping',
    mappingTitleDesc: 'This screen will allow defining what doors are triggered through the door controller boards',
    created: 'Created',
    allSites: 'All Sites',
    selectAccessGroups: 'Select Access Groups',
    yes: 'Yes',
    no: 'No',
    description: 'Description',
    video: {
        notFound: 'Videos not found',
        accessRelatedVideos: 'View Access Related Video',
        videos: 'Videos',
        liveVideo: 'View Live Video',
    },
    siteName: 'Site Name',
    resolved: 'Resolved',
    unresolved: 'Unresolved',
    deleteConfirm: 'Are you sure you want to delete?',
    revokeConfirm: 'Are you sure you want to revoke?',
    selectUser: 'Select User',
    generalInfo: 'General Information',
    doorColor: 'Door Color in Mobile UI',
    accessGroup: 'Access Group',
    accessGroups: 'Access Groups',
    active: 'Active',
    notAvailable: 'Not Available',
    lastHeartBeat: 'Last Heart Beat',
    lastUpdatedAt: 'Last Updated at',
    lastUpdated: 'Last Updated',
    schedule: 'Schedule',
    help: 'Help',
    selectSchedule: 'Select Schedule',
    fetchingData: 'Fetching Data...',
    dataTime: 'Date/Time',
    noPermissionContactAdmin: "You don't have permission to view this section. Please contact your admin.",
    address: 'Address',
    syncLogs: 'Sync Logs',
    source: 'Source',
    units: 'Units',
    backToMainAuth: 'Terminate debug session',
    floors: 'Floors',
    floor: 'Floor',
    available: 'Available',
    mappedTo: 'Mapped to',
    chooseAction: 'Select Action',
    startDateTime: 'Start Date/Time',
    endDateTime: 'End Date/Time',
    fieldCannotBeEmpty: 'TextField cannot be empty',
    enterCorrectHexNumber: 'Please enter correct Hex number: numbers 0-9, letters A-F, could start with 0x',
    enterCorrectSimpleHexNumber: 'Please enter correct Hex number: numbers 0-9, letters A-F',
    phoneNumWithCodeValidationMsg: 'Provide phone number, min 8 and max 15 digits',
    phoneNumFullValidationMsg: 'Provide phone number, 10 digits',
    passwordValidationMsg:
        'At least one upper case, one lower case letter, one digit characters, one special character and min 8 characters in total.',
    backHome: 'Back Home',
    pageNotExist: 'Sorry, this page does not exist',
    openInNewTab: 'Open in New Tab',
    editInNewTab: 'Edit in New Tab',
    min4Max6: 'Enter min 4 and max 6 digits',
    reauthorize: 'Re-authorize',
    deleteConfirmPart1: 'Please confirm by typing',
    deleteConfirmPart2: 'in below text box',
    notAssigned: 'Not Assigned',
    assignUsers: 'Assign Users',
    selectUsers: 'Select Users',
    findUser: 'Find user',
    details: 'Details',
    notProvided: 'Not provided',
    group: 'Group',
    groupName: 'Group Name',
    searchUser: 'Search User',
    sequentialDigitsNotAllowed: 'Sequential digits not allowed.',
    repeatedDigitsNotAllowed: 'Repeated digits not allowed.',
    setPinWith: 'Please set a pin with',
    setPinWithP2: 'digit length (should not be starting with 0)',
    selectAction: 'Select Action',
    removeSelected: 'Delete Selected',
    account: 'Account',
    noSiteMsg1: 'If you want to create tenant entry, please create a ',
    noSiteMsg2: 'site in account',
    device: 'Device',
    info: 'Info',
    passName: 'Pin Name',
    selectValue: 'Select value',
    softReset: 'Soft Reset',
    friendlyName: 'Friendly Name',
    on: 'On',
    off: 'Off',
    softResetConfirm: 'Are you sure you want to reset the App?',
    rebootDeviceConfirm: 'Are you sure you want to reboot the device?',
    softResetHelpTxt:
        'This will restart the Swiftlane app on Access Point without shutting down the device. Use this in case the Swiftlane Reader is stuck on a screen.',
    acu: {
        acuDeviceMapping: 'ACU - Device Mapping',
        addAnotherDevice: 'Add Another Device',
        error: 'Please fill all mapping fields',
        assignAcuConfirm: 'Are you sure you want to assign ACU?',
        clearAcuConfirm: 'Are you sure you want to clear ACU?',
        assignAcu: 'Assign ACU',
        localMqttStatus: 'ACU communication status',
        enableLocalMqttConfirm: 'Are you sure you want to change ACU communication status?',
        localMqttDisabledMsg: 'ACU communication status can be changed only if ACU is assigned to the device',
        moreInfo:
            "ACU, or Access Control Unit, is an independent hardware component seamlessly integrated into the Swiftlane system. Its primary function is to manage access control locally in situations where internet connectivity is unavailable. Once the ACU is installed, both resident and visitor PIN access, as well as BLE unlock features, remain operational even when devices are offline. It's important to note that Face Unlock and Intercom calls are not supported in offline mode. For additional information, please contact our sales team at {sales} or reach out to our support team at {support}.",
        enableAcuWarning: 'Please assign ACU to the device before enabling ACU communication',
        disableWarnMessage: 'Offline access via ACU will not work until the communication status is set to Enabled',
    },
    copiedToClipboard: 'Copied to clipboard',
    couldNotCopyToClipboard: 'Could not copy to clipboard',
    enabled: 'Enabled',
    disabled: 'Disabled',
    enterEmail: 'Enter Email',
    enterCode: 'Enter Code',
    urlValidationMsg: 'Please enter valid URL (https://example.com)',
    title: 'Title',
    inactive: 'Inactive',
    vacant: 'Vacant',
    occupied: 'Occupied',
    fullName: 'Full Name',
    firstNameLastInitials: 'First Name + Last Name Initials',
    lastNameFirstNameInitials: 'First Name Initials + Last Name',
    version: 'Version',
    learn: 'Learn',
    tutorial: 'Tutorial',
    frequentlyQuestions: 'Frequently Asked Questions',
    overview: 'Overview',
    activate: 'Activate',
    billing: {
        accountIsRestricted: 'Your Account is Restricted',
        restrictedMsg: 'Your account has restricted features due to unpaid invoices. Please settle your account to restore full access.',
        activateAccount: 'Activate Account',
        requestAccessMsg: 'Request access for 3 hours',
        requestAccessPurpose: 'Please provide a purpose for requesting access',
        accessEndAt: 'Access will end at',
        activateAccountConfirmation: 'Activation will start the subscription billing cycle. Do you want to continue?',
        activeSuspendedMsg:
            'The account is currently restricted due to non-payment of invoices. Kindly check the pending invoices and pay to re-activate the account. Residents won’t be able to access any doors during restriction. In case of emergency, admins can use ‘Request access for 3 Hours’ button to enable access to residents for 3 hours.',
        newSuspendedMsg:
            'To enable access to doors in the property for all residents, you need to activate your account. Please clear all pending invoices and use "Activate" button to activate access to all residents. Meanwhile, you can use "Request access for 3 hours" button to enable access to residents for 3 hours.',
        newNotSuspendedMsg:
            'To enable access to doors in the property for all residents, you need to activate your account. Your subscription billing cycle will start on the day when the account is activated.',
    },
    days: 'Days',
    or: 'or',
    viewLogs: 'View Logs',
    eventType: 'Event Type',
    roomId: 'Room ID',
    accessPointId: 'Access Point ID',
    timestamp: 'Timestamp',
    userDevice: 'User Device',
    feedLogs: 'Feed Logs',
    showMore: 'Show More',
    showLess: 'Show Less',
    logs: 'Logs',
    activatedAt: 'Activated At',
    linked: 'Linked',
    notLinked: 'Not Linked',
    someEnteredEmailIsInvalid: 'Some of entered email(s) is not a valid email(s)',
    emailConfirmation: 'Email Confirmation',
    emailVerification: 'Email Verification',
    visitorSettings: 'Visitor Settings',
    notFound: 'Not Found',
    userProfile: 'User Profile',
    moreInfo: 'More Info',
    tenantNameTooLong: 'Tenant name should not be more than 32 characters',
    failedAccountLogin: 'Failed to login to the account. Please try again.',
    loggedOut: 'Logged out',
    skipSelectedRows: 'Skip Selected rows',
    unSkipSelectedRows: 'Add skipped rows back',
    accountSummary: 'ACCOUNT SUMMARY (Last 15 Days)',
    totalAccessEvents: 'Total Access Events',
    totalIntercomCalls: 'Total Intercom Calls',
    successfulFaceUnlocks: 'Successful Face Unlocks',
    totalUnlockFailureEvents: 'Total Unlock Failure Events',
    successfulPinUnlocks: 'Successful Pin Unlocks',
    uniqueUsersGrantedAccess: 'No. of Unique Users Granted Access',
    deselectSelectionConfirm: 'Are you sure you want to deselect the selection?',
    unMapScheduleConfirm: 'Are you sure you want to unmap the schedule?',
    unAssignAccountConfirm: 'Are you sure you want to unassign the account?',
    unAssignAccessPointConfirm: 'Are you sure you want to unassign the access point?',
    type: 'Type',
    timezone: 'Timezone',
};
