import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @ts-ignore
import { loadReCaptcha } from 'react-recaptcha-google';
import { ICustomMap } from 'types/ICustomMap';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { setWorkspace } from 'store/workSpace/actions';
import {
    logOutAction,
    removeCurrentAccountFromRecentAuthAction,
    setAuthedUser,
    setRecentAuthsAction,
    setUserTokensAction,
} from 'store/authentication/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { publicRoutes, Roles } from 'config/general';
import { useQuery } from 'react-query';
import { billingStateQueryKey } from 'pages/Billing/config';
import { requestBillingState } from 'pages/Billing/services';
import { setBillingAccountInfo } from 'store/billing/actions';
import routes from 'config/routes';
import { requestAuthAccount } from 'pages/CommandCenter/Accounts/services';
import { authAccountQueryKey } from 'pages/CommandCenter/Accounts/config';
import { userProfileQueryKey } from 'pages/Users/config';
import { requestGerUserProfile } from 'pages/Users/services';
import { multiAccountAutoLoginQueryKey } from 'components/NavSideBar/config';
import { fetchMultiAccountAutoLogin } from 'components/NavSideBar/services';
import { cacheRecentAuths, cacheUserTokens, getCachedUserTokens } from 'utils/auth';
import parseJwt from 'utils/parseJwt';
import { toast } from 'react-toastify';
import { textsCommon } from 'texts/index';
import useRegisterPushMessaging from 'hooks/useRegisterPushMessaging';
// import useSupportGpt from 'hooks/useSupportGpt';
import useAnalytics from 'hooks/useAnalytics';
import { getUserRingtoneName } from 'utils/ringtone';

interface IUseApp {
    isLoggedIn: boolean;
    isLoading: boolean;
    isAdmin: boolean;
    isAccountRestricted: boolean;
    isNotRestrictedPage: boolean;
}

const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const useApp = (): IUseApp => {
    const { isLoggedIn, recentAuths } = useSelector(({ authentication }: ICustomMap) => authentication || {});
    const currentWorkspace = useSelector(({ workspace }: ICustomMap) => workspace);
    const { isAccountRestricted } = useSelector(({ billing }: ICustomMap) => billing);

    const ldClient = useLDClient();

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const isPublicRoute = useMemo(() => publicRoutes.includes(location.pathname), [location]);

    const { data: accountData, isLoading: isLoadingAccount } = useQuery({
        queryKey: authAccountQueryKey,
        queryFn: () => requestAuthAccount(),
        staleTime: 0,
        enabled: isLoggedIn && !isPublicRoute,
        retry: 2,
        onSuccess: ({ respData }) => {
            dispatch(setWorkspace(respData || null));
        },
        onError: () => {
            toast.error(textsCommon.failedAccountLogin);
            const tokens = getCachedUserTokens();
            const { company_id: companyId } = parseJwt(tokens?.access_token || '');
            dispatch(removeCurrentAccountFromRecentAuthAction(companyId || ''));
            history.push(routes.auth.enterWorkspace);
        },
    });

    const workspace = accountData?.respData;

    const { data: profileData, isLoading: isLoadingProfile } = useQuery({
        queryKey: [userProfileQueryKey, workspace?.id_str],
        queryFn: () => requestGerUserProfile(),
        staleTime: 0,
        retry: 2,
        enabled: isLoggedIn && !isPublicRoute && !!workspace?.id_str,
        onSuccess: ({ respData }) => {
            const ringtone = getUserRingtoneName(respData?.id_str || '');
            const res = respData ? { ...respData, ringtone } : null;
            dispatch(setAuthedUser(res));
        },
    });

    useQuery({
        queryKey: multiAccountAutoLoginQueryKey,
        queryFn: fetchMultiAccountAutoLogin,
        enabled: isLoggedIn && !isPublicRoute && !!workspace?.id_str,
        onSuccess: ({ respData }) => {
            const recentList = { ...(recentAuths || {}) };
            respData?.forEach(
                ({
                    workspace_id_str,
                    user_id_str,
                    common_user_id,
                    access_token,
                    refresh_token,
                    workspace_display_name,
                    workspace_name,
                    identity_provider,
                }) => {
                    const cachedLogin = recentList?.[workspace_id_str];
                    // current one is already cached, update it
                    if (cachedLogin && workspace?.id_str === workspace_id_str) {
                        recentList[workspace_id_str] = {
                            ...cachedLogin,
                            userId: user_id_str,
                            commonUserId: common_user_id,
                            shortName: workspace_display_name,
                            workspaceName: workspace_name,
                            access_token: access_token || cachedLogin.access_token,
                            refresh_token: refresh_token || cachedLogin.refresh_token,
                            identityProvider: identity_provider,
                            timestamp: Date.now(),
                        };
                    }
                    recentList[workspace_id_str] = {
                        ...(recentList[workspace_id_str] || {}),
                        id_str: workspace_id_str,
                        userId: user_id_str,
                        commonUserId: common_user_id,
                        shortName: workspace_display_name,
                        workspaceName: workspace_name,
                        access_token: access_token || '',
                        refresh_token: refresh_token || '',
                        identityProvider: identity_provider,
                        timestamp: workspace_id_str === workspace?.id_str ? Date.now() : recentList[workspace_id_str]?.timestamp || null,
                    };
                },
            );
            if (recentList) {
                const currentFreshTokens = workspace?.id_str ? recentList[workspace.id_str] : null;

                // update current fresh tokens in local storage
                if (currentFreshTokens) {
                    cacheUserTokens({
                        access_token: currentFreshTokens.access_token,
                        refresh_token: currentFreshTokens.refresh_token,
                    });
                    setUserTokensAction({
                        access_token: currentFreshTokens.access_token,
                        refresh_token: currentFreshTokens.refresh_token,
                    });
                }

                cacheRecentAuths(recentList);
                dispatch(setRecentAuthsAction(recentList));
            }
        },
    });

    const user = profileData?.respData;
    const isAdmin = useMemo(() => user?.role === Roles.admin, [user]);

    // fetch account billing state
    useQuery({
        queryKey: [billingStateQueryKey, { company_id: workspace?.id_str || '' }],
        queryFn: requestBillingState,
        staleTime: 0,
        enabled: !!workspace?.id_str && isAdmin,
        onSuccess: ({ respData }) => {
            dispatch(setBillingAccountInfo(respData || {}));
        },
    });

    useAnalytics();
    useRegisterPushMessaging();

    // const { initSupportGpt } = useSupportGpt();
    //
    // useEffect(() => {
    //     if (isLoggedIn) {
    //         initSupportGpt();
    //     }
    // }, [initSupportGpt, isLoggedIn]);

    useEffect(() => {
        if (ldClient && user?.id_str && currentWorkspace?.id_str) {
            ldClient.identify({
                key: currentWorkspace.id_str,
                custom: {
                    user_id: user.id_str,
                },
            });
        }
    }, [user, ldClient, currentWorkspace, dispatch]);

    useEffect(() => {
        if (reCaptchaSiteKey) {
            loadReCaptcha(reCaptchaSiteKey);
        }
    }, []);

    useEffect(() => {
        if (isPublicRoute) {
            dispatch(logOutAction());
            dispatch(setWorkspace(null));
        }
    }, [dispatch, isPublicRoute]);

    const isNotRestrictedPage = useMemo(
        () => location.pathname.includes(routes.billing.root) || location.pathname.includes(routes.auth.enterWorkspace),
        [location],
    );

    const isSwiftlaneUser = useMemo(() => user?.email?.includes('swiftlane.com'), [user]);

    return useMemo(
        () => ({
            isLoading: isLoadingAccount || isLoadingProfile,
            isAdmin,
            isLoggedIn,
            isAccountRestricted: isAccountRestricted && !isSwiftlaneUser,
            isNotRestrictedPage,
        }),
        [isLoadingAccount, isLoadingProfile, isAdmin, isLoggedIn, isAccountRestricted, isSwiftlaneUser, isNotRestrictedPage],
    );
};

export default useApp;
